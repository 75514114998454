import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

import PropTypes from 'prop-types'
import Section from '../components/cms/Section'
import HaveQuestions from '../components/repairs/HaveQuestions'
import { convertDateTimeString } from '../utils/previewDateFormat'
import { TableOfContentsHTML } from '../components/TableOfContents'
import { CombineSections } from '../components/cms/SectionLayout'

const CmsPageSectionRenderer = ({ data }) => {
  let page = data.page
  const sections = page.sections || []
  if (sections.filter(s => s.strapi_component === 'section.have-questions').length === 0) {
    // ** FOR CMS PAGES - WE WANT TO ADD HAVE QUESTIONS SECTION TO THE BOTTOM OF THE PAGE **
    // ** WITH ONE EXCEPTION: IF THERE IS A CONTACT US MAP SECTION, WE WANT TO ADD HAVE QUESTIONS ABOVE IT **
    // if there is a "section.contact-us-map" section, add a "section.have-questions" section before
    // otherwise, add a "section.have-questions" section at the end
    const contactUsMapIndex = sections.findIndex(
      s => s.strapi_component === 'section.contact-us-map'
    )
    if (contactUsMapIndex !== -1) {
      sections.splice(contactUsMapIndex, 0, {
        strapi_component: 'section.have-questions',
        id: 'have-questions',
      })
    } else {
      sections.push({
        strapi_component: 'section.have-questions',
        id: 'have-questions',
      })
    }
  }
  const pageSections = CombineSections(sections || [])
  return (
    <Layout breadcrumbs={true} canonical={page.url} title={page.title}>
      <Seo seo={page.seo} title={page.title} canonical={page.url} article={page} />
      <div className={'overflow-hidden bg-center bg-fixed'}>
        <h1 className="w-10/12 mx-auto max-w-[1366px] py-5 lg:py-10 text-2xl font-black xl:text-4xl">
          {' '}
          {page.title}
        </h1>
      </div>
      <section
        className={'cms max-w-[1366px] mx-auto w-10/12'}
        dangerouslySetInnerHTML={{ __html: page.content }}
      />
      {page.table_of_contents ? <TableOfContentsHTML sections={page.sections} /> : null}
      {pageSections &&
        pageSections.map(section => {
          return (
            <Section
              key={section.id}
              section={section}
              className={'w-10/12 max-w-[1366px] mx-auto cms'}
            />
          )
        })}

      <div className="w-10/12 max-w-[1366px] mx-auto  py-10 hidden">
        Published At: {convertDateTimeString(page.publishedAt)}
        Updated At: {convertDateTimeString(page.updatedAt)}
      </div>
    </Layout>
  )
}

// proptypes for the page
CmsPageSectionRenderer.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      publishedAt: PropTypes.any,
      updatedAt: PropTypes.any,
      url: PropTypes.string,
      seo: Seo.propTypes.seo,
      table_of_contents: PropTypes.bool,
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          heading: PropTypes.string,
          content: PropTypes.string,
          videos: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              url: PropTypes.string,
            })
          ),
          strapi_component: PropTypes.string,
        })
      ),
    }),
  }),
}

export default CmsPageSectionRenderer
